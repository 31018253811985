 <template>
 <div>
<van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
<van-form @submit="onSubmit">
  

<van-field v-model="deviceName" label="设备名称" :rules="[{ required: true, message: '请填写设备名称' } ]"/>

<van-field v-model="imei" type="digit" label="设备IMEI" :rules="[{ required: true, message: '请填写设备IMEI' }]" right-icon="scan"   @click="myscanQRCode"/>

<van-field v-model="location"   label="地理位置" :rules="[{ required: true, message: '请填写地理位置' }]" right-icon="location-o"   @click="myopenLocation"/>
<van-field v-model="deviceAddr"   label="详细地址" :rules="[{ required: true, message: '请填写详细地址' }]" right-icon="location-o"   @click="myopenLocation"/>

  
  <div style="margin: 16px;">
    <van-button   block type="primary" native-type="submit">提交</van-button>

 
  </div>
</van-form>
 </div>
</template>
 <script>
import wx from "weixin-js-sdk";
//import wx from "jweixin-1.6.0";

import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);

import router from "../../router";

const mytimestamp = (new Date().getTime() / 1000).toString();

const mynonceStr = "se1wdWDAwd";

export default {
  data() {
    return {
      imei: "",
      deviceName: "",
      location: "",
      deviceAddr: ""
    };
  },
  methods: {
    myscanQRCode() {
      let that = this;

      wx.ready(() => {
        wx.scanQRCode({
          needResult: 1,
          scanType: ["barCode", "qrCode"],
          desc: "scanQRCode desc",
          success: function(res) {
            that.imei = res.resultStr.split(",")[0];
          }
        });
      });
    },

    mygetLocation() {
      Toast.loading({
        message: "获取位置，请稍后...",
        forbidClick: true
      });

      let that = this;
      wx.getLocation({
        //
        type: "gcj02", // 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于 wx.openLocation 的坐标
        success: function(res) {
          that.location = res.longitude + "," + res.latitude;
          Toast.clear();
          window.console.log(res.latitude);
          window.console.log(res.longitude);
        }
      });
    },

    myopenLocation() {
      let that = this;

      wx.openLocation({
        latitude: that.location.split(",")[1], // 纬度，范围为-90~90，负数表示南纬
        longitude: that.location.split(",")[0], // 经度，范围为-180~180，负数表示西经
        scale: 18, // 缩放比例
        //name:"测试",
        // address:"测试详细地址",
        success: function(r) {
          window.console.log(r);
        }
      });
    },

    getDeviceAddr() {
      //let that= this;
      //latitude: that.location.split(',')[1], // 纬度，范围为-90~90，负数表示南纬
      //  longitude: that.location.split(',')[0], // 经度，范围为-180~180，负数表示西经
      //key=WBCBZ-HNIWO-HBZWM-SJ6XW-L3HB3-CDFVS
    },

    onSubmit() {
      var param = {
        deviceName: this.deviceName,
        imei: this.imei,
        location: this.location,
        deviceAddr: this.deviceAddr
      };
      //let that = this;

      this.postRequest("/Device/add", param).then(res => {
        window.console.log(res);
        if (res.code == 204) {
          //绑定成功、
          Toast("绑定成功");

          router.push("/device");
        } else {
          Toast(res.message);
        }
      });
    }
  },


created()
{
  /* 
let signLink = "";
    const ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      // 记录进入app时的url
      // alert('苹果终端设备')
      signLink = decodeURIComponent(wx.signurl());
    } else if (/(android)/i.test(ua)) {
      // alert('安卓终端设备')
      signLink = window.location.href;
    } else {
      // alert('PC终端设备')
      signLink = window.location.href;
    }*/


 let purl = /(android)/i.test(navigator.userAgent.toLowerCase()) ? window.location.href.split('#')[0] : window.localStorage.getItem('scanUrl');
  



    wx.config({
      debug: false,
      appId: "wxc9126d26c73f1549",
      timestamp: mytimestamp,
      nonceStr: mynonceStr,
      signature: this.sha1(
        "jsapi_ticket=" +
          window.localStorage.getItem("jsapi_ticket") +
          "&noncestr=" +
          mynonceStr +
          "&timestamp=" +
          mytimestamp +
          "&url=" +
          purl
      ),
      jsApiList: ["scanQRCode", "getLocation", "openLocation"]
    });

},




  mounted() {
    
    this.mygetLocation();
    this.myscanQRCode();
  }
};
</script>



 