/*
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/goods'
  },
  {
    name: 'user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
*/



import Vue from 'vue';
import VueRouter from 'vue-router';


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);



 
//页面视图，以实际项目为准
import SettingIndex from './view/cart/index.vue';
import Device from './view/device/index.vue';
import Home from './view/home/index.vue';
import Login from './view/login/index.vue';
import About from './view/about/index.vue';
import Message from './view/message/index.vue';
import Register from './view/register/index.vue';



import BindDevice from './view/binddevice/index.vue';


//路由表
const routes = [
  {
    path: '/home',
    name: '/home',
    component: Home
  },
  {
    path: '/device',
    name: '/device',
    component: Device
  },

  {
    path: '/binddevice',
    name: '/binddevice',
    component: BindDevice
  },

  {
    path: '/login',
    name: '/login',
    component: Login
  },

  {
    path: '/about',
    name: '/about',
    component: About
  },

  {
    path: '/message',
    name: '/message',
    component: Message
  },

  {
    path: '/register',
    name: '/register',
    component: Register
  },




  {
    path: '/user',
    name: '/user',
    component: SettingIndex
  },
];
const router = new VueRouter({
  routes,
  mode: 'history'
  //mode: 'hash'
  
});

export default router