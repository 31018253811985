<template>
<div >
  
 <van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
  <div id="doorStatus" class="chart">
  </div>

<div id="deviceStatus" class="chart">
  </div>

<div id="weekLog" class="chart">
  </div>
</div>

</template>

<script>
// 引入基本模板
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");

require("echarts/lib/chart/pie");

// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
import Vue from "vue";
import { Image, Notify, Toast } from "vant";

Vue.use(Image);
Vue.use(Notify);
Vue.use(Toast);

import router from "../../router";

const wxauthUrl =
  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc9126d26c73f1549&redirect_uri=https%3a%2f%2fiotapi.wlank.com%2foauth%2fwechatcallback&response_type=code&scope=snsapi_userinfo&state=Wlank#wechat_redirect";

export default {
  config: {
    navigationBarTitleText: "首页",
    //backgroundColor: '#F4F4F4',
    navigationBarTextStyle: "black"
  },
  data() {
    return {
      onlineCount: 0,
      offlineCount: 0,

      openCount: 0,
      closeCount: 0,

      weekLog: [0, 0, 0, 0, 0, 0, 0],
      weekDate: ["", "", "", "", "", "", ""]
    };
  },
  methods: {
    getChartData() {
      var param = {};

      let that = this;

      this.getRequest("/Device/chart", param).then(res => {
        window.console.log(res.data);

        if (res.data) {
          if (res.code == 200) {
            that.onlineCount = res.data.onlineCount;
            that.offlineCount = res.data.offlineCount;
            that.openCount = res.data.openCount;
            that.closeCount = res.data.closeCount;
            that.weekLog = res.data.weekLog;
            that.weekDate = res.data.weekDate;

            // that.weekLog
            // weekDate.weekLog

            localStorage.setItem("reloadhome", false);

            this.showDoorStatus();
            this.showWeekLog();
            this.showDeviceStatus();
          }
        }
      });
    },

    onLoad() {
      //this.getChartData();

      let token = window.localStorage.getItem("NbiotToken");

      let ExpiredTime = window.localStorage.getItem("ExpiredTime");

      if (!token) {
        router.push("/login");
      }

      if (ExpiredTime) {
        if (ExpiredTime < parseInt(new Date().getTime() / 1000) - 300) {
          router.push("/login");
        }
      }
    },

    showWeekLog() {
      var option;

      option = {
        title: {
          text: "一周报警趋势"
        },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: "category",
          data: this.weekDate,

          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
            rotate: 45 //代表逆时针旋转45度
          }
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            symbol: "none",
            data: this.weekLog,
            type: "line",
            smooth: true
          }
        ]
      };

      let myChart = echarts.init(document.getElementById("weekLog"));
      myChart.setOption(option);
    },

    showDeviceStatus() {
      let option = {
        title: {
          text: "设备状态"
        },
        tooltip: {},

        xAxis: {
          data: ["在线", "离线"]
        },
        yAxis: {},

        series: [
          {
            name: "设备数",
            type: "bar",
            data: [
              {
                value: this.onlineCount,
                itemStyle: {
                  color: "#a90000"
                }
              },

              {
                value: this.offlineCount,
                itemStyle: {
                  color: "#ccc"
                }
              }
            ]
          }
        ]
      };

      let myChart = echarts.init(document.getElementById("deviceStatus"));
      myChart.setOption(option);
    },

    showDoorStatus() {
      let option = {
        title: {
          text: "门磁状态"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)" //a 系列名称，b 数据项名称，c 数值，d 百分比
        },
        legend: {
          orient: "horizontal",

          x: "center",
          y: "bottom"
        },
        series: [
          {
            name: "门磁状态",
            type: "pie",
            radius: "50%",
            data: [
              { value: this.openCount, name: "打开" },
              { value: this.closeCount, name: "关闭" }
            ],

            label: {
              normal: {
                formatter: "{b}:{c}",
                textStyle: {
                  fontWeight: "normal",
                  fontSize: 15
                }
              }
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };

      let myChart = echarts.init(document.getElementById("doorStatus"));
      myChart.setOption(option);
    },

    loginWechat() {
      let wxExpiredTime = localStorage.getItem("wxExpiredTime");

      if (!wxExpiredTime) {
        window.location.href = wxauthUrl;
      } else {
        let wxrefreshtoken = localStorage.getItem("wxrefreshtoken");

        if (wxrefreshtoken != undefined) {
          if (parseInt(new Date().getTime() / 1000) > wxExpiredTime - 300) {
            var param = { token: localStorage.getItem("wxrefreshtoken") };

            this.getRequest("/oauth/wechat/wxrefreshtoken", param).then(res => {
              window.console.log(res);
              if (res.code == 200) {
                localStorage.setItem("openid", res.data.openid);

                localStorage.setItem("wxaccesstoken", res.data.access_token);
                localStorage.setItem("wxrefreshtoken", res.data.refresh_token);

                localStorage.setItem(
                  "wxExpiredTime",
                  parseInt(new Date().getTime() / 1000) + 7200
                );

                //router.push("/home");
              } else {
                // window.console.log("1111");
                window.location.href = wxauthUrl;

                // router.push("/register");
              }
            });
          }
        } else {
          window.location.href = wxauthUrl;
        }
      }
    },

    getWxJSapiTicket() {
      var param = {};

      let ExpiredTime = localStorage.getItem("ticket_expires_in");

      //let that = this;

      window.console.log("getWxJSapiTicket");
      if (
        !ExpiredTime ||
        ExpiredTime == undefined ||
        ExpiredTime == "undefined"
      ) {
        this.getRequest("/Device/getwxjsticket", param).then(res => {
          window.console.log(res.data);

          if (res.code == 200) {
            if (res.data.s == 0) {
              localStorage.setItem("jsapi_ticket", res.data.ticket);
              localStorage.setItem(
                "ticket_expires_in",
                res.data.ticket_expires_in
              );
            }
          }
        });
      } else {
        if (parseInt(new Date().getTime() / 1000) > ExpiredTime - 300) {
          this.getRequest("/Device/getwxjsticket", param).then(res => {
            window.console.log(res.data);

            if (res.code == 200) {
              if (res.data.s == 0) {
                localStorage.setItem("jsapi_ticket", res.data.ticket);
                localStorage.setItem(
                  "ticket_expires_in",
                  res.data.ticket_expires_in
                );
              }
            }
          });
        }
      }
    },

    loginwx(OpenId) {
      let ExpiredTime = localStorage.getItem("ExpiredTime");
      var param = { OpenId: OpenId };
      if (!ExpiredTime) {
        this.postRequest("/loginwx", param).then(res => {
          window.console.log(res);
          if (res.code == 200) {
            localStorage.setItem("NbiotToken", res.data);

            localStorage.setItem(
              "ExpiredTime",
              parseInt(new Date().getTime() / 1000) + 43200
            );
            //router.push("/home");
            //获取微信公众号JS权限
            this.getWxJSapiTicket();

            this.getChartData();
            //this.showDoorStatus();
            //this.showWeekLog();
            //this.showDeviceStatus();
          } else {
            //未注册

            router.push("/login");
          }
        });
      } else {
        if (parseInt(new Date().getTime() / 1000) > ExpiredTime - 300) {
          this.postRequest("/loginwx", param).then(res => {
            window.console.log(res);
            if (res.code == 200) {
              localStorage.setItem("NbiotToken", res.data);

              localStorage.setItem(
                "ExpiredTime",
                parseInt(new Date().getTime() / 1000) + 43200
              );
              //router.push("/home");
              //加载图表
              this.getWxJSapiTicket();

              this.getChartData();
            } else {
              //未注册

              router.push("/login");
            }
          });
        } else {
          //加载图表

          this.getWxJSapiTicket();
          this.getChartData();
        }
      }
    }
  },
  mounted() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true
    });

    let openid = this.$route.query.openid;
    //let sopenid = localStorage.getItem("openid");

    if (!openid) {
      this.loginWechat();
    } else {
      localStorage.setItem("openid", this.$route.query.openid);

      localStorage.setItem("wxaccesstoken", this.$route.query.accesstoken);
      localStorage.setItem("wxrefreshtoken", this.$route.query.refreshtoken);

      localStorage.setItem(
        "wxExpiredTime",
        parseInt(new Date().getTime() / 1000) + 7200
      );
    }

    this.loginwx(localStorage.getItem("openid"));

    this.$toast.clear();
  }
};
</script>

<style lang="less">
.chart {
  width: 100%;
  height: 260px;
  padding-top: 0.6rem;
}
.bg_yellow {
  backgroundcolor: #ccc !important;
}

.sliderContent {
  padding: 50px 50px 0px 50px;
}
.buttonContent {
  padding: 50px;
}
#weekLog {
  margin-bottom: 30px;
}
</style>
