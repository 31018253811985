<template>
 <div>

 <van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
<van-form @submit="onSubmit">
  <van-field
    v-model="AdminName"
    name="用户名"
    label="用户名"
    placeholder="用户名"
    :rules="[{ required: true, message: '请填写用户名',trigger:'onSubmit' },
   


 { pattern: /^[a-zA-Z][a-zA-Z0-9_]{5,16}$/, message: '用户名5到16位（首字符为字母，可包括数字，下划线）'}

    
    
    ]"
  />

<van-field
    v-model="Phone"
    name="Phone"
    label="手机号码"
    placeholder="手机号码"
    :rules="[{ required: true, message: '请填写手机号码',trigger:'onSubmit' },
    
    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
    ]"
  />


  <van-field
    v-model="Captcha"
    name="验证码"
    label="验证码"
    placeholder="验证码"
    :rules="[{ required: true, message: '请输入验证码' ,trigger:'onSubmit'}]"
  >
  <template #button>
          <van-button size="small" type="primary"  native-type="button"    @click="sendSmsCode" :disabled="isSmsSend">{{sendBtnText}}</van-button>
        </template>
  </van-field>


  <van-field
    v-model="password"
    type="password"
    name="密码"
    label="密码"
    placeholder="密码"
    :rules="[{ required: true, message: '请填写密码',trigger:'onSubmit' }]"
  />

<van-field
    v-model="repassword"
    type="password"
    name="密码"
    label="确认密码"
    placeholder="确认密码"
    :rules="[{ required: true, message: '请确认密码',trigger:'onSubmit' },

 
    { validator, message: '两次输入密码不一致' }
    
    
    ]"
  />



  <div style="margin: 16px;">
    <van-button  block type="primary" native-type="submit">注册</van-button>
  </div>

 


</van-form>
 </div>
</template>

<script>
import Vue from "vue";
import { Toast, Form, Field, Button, Image, Notify } from "vant";

import router from "../../router";
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Image);
Vue.use(Notify);

export default {
  data() {
    return {
      AdminName: "",
      Phone: "",
      Captcha: "",
      password: "",
      repassword: "",
      OpenId: "",
      // 是否已经发送了验证码
      isSmsSend: false,
      // 文本
      sendBtnText: "获取验证码",
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60
    };
  },
  methods: {
    validator(value) {
      return value == this.password;
    },

    /**
     * 重置倒计时
     */
    reset() {
      // 重置按钮可用
      this.isSmsSend = false;
      // 重置文本内容
      this.sendBtnText = "获取验证码";
      if (this.timer) {
        // 存在计时器对象，则清除
        clearInterval(this.timer);
        // 重置秒数，防止下次混乱
        this.counter = 60;
        // 计时器对象重置为空
        this.timer = null;
      }
    },

    /**
     * 倒计时
     */
    countDown() {
      //window.console.log("1111");
      // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
      this.timer = setInterval(() => {
        // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
        this.sendBtnText = `(${this.counter}秒)后重新获取`;
        this.counter--;
        if (this.counter < 0) {
          // 当计时小于零时，取消该计时器
          clearInterval(this.timer);
          this.isSmsSend = false;
          this.sendBtnText = `重新获取`;
          this.counter = 60;
        }
      }, 1000);
    },

    /**
     * 发送验证码
     */
    sendSmsCode() {
      window.console.log("rewr");

      // 判断手机号是否已经输入
      if (!this.Phone) {
        //this.$notify("请输入手机号");

        Toast("请输入手机号");

        // Notify({ type: 'danger', message: '请输入手机号' });
        return false;
      }
      // 判断手机号是否符合要求

      var r = /^1[3456789]\d{9}$/; //匹配模式

      if (!r.test(this.Phone)) {
        Toast("手机号格式错误！");
        return false;
      }

      // 调用接口，发送短信验证码
      // 这部分放调用发送短信的接口，这里先不做任何功能，主要先把按钮倒计时的功能实现
      // 将按钮禁用，防止再次点击

      var param = { Phone: this.Phone };

      this.getRequest("/Device/getsmscode", param).then(res => {
        window.console.log(res.data);

        //Toast(res.message);
      });

      this.isSmsSend = true;
      // 开始倒计时，60s之后才能再次点击
      this.countDown(); // 这里实现倒计时的功能，文章下面开始介绍
    },

    loginwx() {
      var param = { OpenId: this.OpenId };

      this.postRequest("/loginwx", param).then(res => {
        window.console.log(res);
        if (res.code == 200) {
          localStorage.setItem("NbiotToken", res.data);

          localStorage.setItem(
            "ExpiredTime",
            parseInt(new Date().getTime() / 1000) + 43200
          );
          router.push("/home");
        }
      });
    },

    onSubmit() {
      this.OpenId = localStorage.getItem("openid");

      var param = {
        AdminName: this.AdminName,
        Name: this.AdminName,
        Phone: this.Phone,
        Password: this.password,
        OpenId: this.OpenId,
        Captcha: this.Captcha
      };
      let that = this;

      this.postRequest("/sysTenant/wxadd", param).then(res => {
        window.console.log(res);
        if (res.code == 204) {
          //注册成功,登录

          that.loginwx();
        } else {
          Toast(res.message);
        }
      });
    }
  }
};
</script>