<template>
  <div>
    <!--<img class="user-poster" src="https://img.yzcdn.cn/public_files/2017/10/23/8690bb321356070e0b8c4404d087f8fd.png">-->
    
 <van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
    <!--<van-row class="user-links">
      <van-col span="6">
        <van-icon name="pending-payment" />
        待付款
      </van-col>
      <van-col span="6">
        <van-icon name="records" />
        待接单
      </van-col>
      <van-col span="6">
        <van-icon name="tosend" />
        待发货
      </van-col>
      <van-col span="6">
        <van-icon name="logistics" />
        已发货
      </van-col>
    </van-row>-->

     
      
      <van-cell>
      <van-row >
     <!-- <van-col span="8" class="left">
      <van-icon name="label-o" />
      </van-col>-->
      <van-col span="12" class="title">
          <van-icon name="label-o" />
        <span  class="mt10">使用说明</span>
      </van-col>
      <van-col span="12" class="right">
    <van-icon name="arrow" />
    </van-col>
     </van-row>
    </van-cell>

<van-cell>
      <van-row >
      <van-col span="12" class="title">
          <van-icon name="info-o" />
       <span  class="mt10"> 关于</span>
      </van-col>
      <van-col span="12" class="right">
    <van-icon name="arrow" />
    </van-col>
     </van-row>
    </van-cell>


    <van-cell   @click="logoutHandle">
      <van-row   >
     <van-col span="12" class="title" bindtap="logoutHandle">
        <van-icon name="share-o" />
        <span class="mt10"> 退出</span>
      </van-col>
      <van-col span="12" class="right">
    <van-icon name="arrow" />
    </van-col>
     </van-row>
    </van-cell>

 
     
  </div>
</template>
 
 
<script>
import Vue from "vue";
import { Row, Col, Icon, Cell, CellGroup, Image } from "vant";
import router from "../../router";

Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Image);

export default {
  data() {
    return {};
  },
  methods: {
    onLoad() {
      let token = window.localStorage.getItem("NbiotToken");

      let ExpiredTime = window.localStorage.getItem("ExpiredTime");

      if (!token) {
        router.push("/login");
      }

      if (ExpiredTime) {
        if (ExpiredTime < parseInt(new Date().getTime() / 1000) - 300) {
          router.push("/login");
        }
      }
    },

    logoutHandle() {
      //window.console.log("dsadsad");
      var param = {};
      this.getRequest("/logout", param).then(res => {
        window.console.log(res);
        if (res.code == 204) {
          localStorage.setItem("NbiotToken", "");

          localStorage.setItem("ExpiredTime", 0);
          router.push("/login");
        }
      });
    }
  },
    mounted() {
       
      this.onLoad();
    }
};
</script>

 

<style lang="less">
.right {
  text-align: right;
  color: #999;
}
.left {
  text-align: left;
  color: #999;
}
.title {
  font-size: 16px;
  color: #333;
  display: flex;
  float: left;
}

.mt10 {
  margin-left: 10px;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
