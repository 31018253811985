<template>
<div>
 

 <van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
<van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-bottom: 60px;">

<van-empty description="暂无数据" v-show="empty"/>

  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad" v-show="show"
  >
    <van-cell v-for="item in list" :key="item.index"> 
    
    
    
    <van-row>
  <van-col span="8">
    
    <div class="left_icon menci_div">
        <van-icon class="iconfont menci" class-prefix='icon' slot="icon"   name="menci"></van-icon>


      </div>
      </van-col>

  <van-col span="8">


<van-row>
  <van-col span="24">门磁{{item.name}}</van-col>
 
</van-row>

<van-row>



  <van-col span="24">
    <van-tag type="success" class="tag" v-if="item.netStatus==1">在线</van-tag>

    <van-tag  class="tag" color="#ccc" v-else>离线</van-tag>


  <van-tag plain type="primary" class="tag"  v-if="item.open_state==1" >门打开</van-tag>

  <van-tag  plain class="tag" color="#ccc" v-else>门关闭</van-tag>
  
  </van-col>
  
 
</van-row>



  </van-col>
  <van-col span="8"> 
  
  
  
  
<van-row>
  <van-col span="24">
<div class="right"><van-switch   :value="item.defense"    size="20px"    @input="onInput($event,item.id)" />
  
  

  </div>

  </van-col>
   <van-col span="24">
     <div class="right">

<van-popover
  v-model="item.showPopover"
  trigger="click"
   theme="dark"
   placement="bottom-end"
  :actions="actions"
  @select="onSelect"
>
  <template #reference>
       
     <van-icon name="ellipsis" class="more" />

</template>
</van-popover>

</div>

   </van-col>
 
</van-row>
  
  
  
  </van-col>


</van-row>
    
     
    </van-cell>
 


  </van-list>
</van-pull-refresh>
</div>
</template>

<script>
import Vue from "vue";
import {
  List,
  Cell,
  PullRefresh,
  Row,
  Col,
  Switch,
  Tag,
  Popover,
  Toast,
  Image
} from "vant";

import router from "../../router";

Vue.use(List);
Vue.use(Cell);
Vue.use(PullRefresh);
Vue.use(Row);
Vue.use(Col);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Popover);
Vue.use(Toast);
Vue.use(Image);

export default {
  data() {
    return {
      actions: [{ text: "编辑" }, { text: "解绑" }],
      pageNo: 0,
      totalPage: 0,
      pageSize: 10,
      checked: true,
show: true,
empty:false,
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  methods: {
    getroadList() {
      //pageNo=1&pageSize=10

      var param = { pageNo: this.pageNo, pageSize: this.pageSize };

      let that = this;

      this.getRequest("/Device/page", param).then(res => {
        window.console.log(res.data);

        if (res.code == 200) {
          //totalPage
          that.totalPage = res.data.totalPage;

          var rows = res.data.rows;

          window.console.log(res.data.rows);

          if (rows.length > 0) {
            for (let index = 0; index < rows.length; index++) {
              var arr = {
                id: rows[index].id,
                name: rows[index].deviceName,
                type: 1,
                open_state: 1,
                netStatus: rows[index].netStatus,
                defense: rows[index].defense,
                showPopover: false
              };
              that.list.push(arr);
            }
          } else {
            that.loading = false;
            that.finished = true;
          }
 
        } else {
          Toast(res.message);
        }

         if(that.list.length==0)
        { that.show=false;
        that.empty=true;
        }
      });
    },

    onSelect(action) {
      Toast(action.text);
    },

    onLoad() {
      let token = window.localStorage.getItem("NbiotToken");

      let ExpiredTime = window.localStorage.getItem("ExpiredTime");

      if (!token) {
        router.push("/login");
      }

      if (ExpiredTime) {
        if (ExpiredTime < parseInt(new Date().getTime() / 1000) - 300) {
          router.push("/login");
        }
      }

      this.loading = false;
      // this.finished = true;
      if (this.pageNo == 0 || this.pageNo < this.totalPage) {
        this.pageNo++;
        Toast.loading({
          message: "加载中...",
          forbidClick: true
        });
        this.getroadList();
        Toast.clear();
        this.loading = false;
      } else {
        this.refreshing = false;

        this.loading = true;
        this.finished = true;
      }

      setTimeout(() => {}, 1000);
    },
    onRefresh() {
      // 清空列表数据
      //this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      this.onLoad();
    },

    change() {
      Toast("checked");
    },

    onInput(event, index) {
      var data1 = this.list.filter(function(item) {
        return item.id == index;
      });

      data1[0].defense = event;

      //window.console.log(this.list);
      //window.console.log(data1);

      var param = { defense: event, Id: index };

      this.postRequest("/Device/defense", param).then(res => {
        //window.console.log(res);

        if (res.code == 204) {
          Toast((event ? "布防" : "撤防") + "成功");
        } else {
          Toast(res.message);
        }
      });
    }
  }
};
</script>

<style lang="less">
.van-cell {
  border-bottom: 1px solid #eee;
}
.more {
  font-size: 28px !important;
  margin-right: 10px;
}
.tag {
  margin-left: 6px;
  margin-right: 6px;
}

.menci_div {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid #70bc46 1px;
  background: #70bc46;
  line-height: 40px;
}

.menci {
  font-size: 36px !important;
  color: #fff;
}

.right {
  text-align: right;
}

.tag-online {
  font-size: 14px;
  margin-top: 4px;
  border-radius: 4px;
  color: #fff;
  background-color: green;
  border: 1px solid #f1f1f1;
  line-height: 25px;
  width: 50px;
  text-align: center;
}

.tag-offline {
  font-size: 14px;
  margin-top: 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #999;
  border: 1px solid #f1f1f1;
  line-height: 25px;
  width: 50px;
  text-align: center;
}

.tag-open {
  font-size: 14px;
  margin-top: 4px;
  border-radius: 4px;
  color: green;
  background-color: #fff;
  border: 1px solid #999;
  line-height: 25px;
  width: 50px;
  text-align: center;
}

.tag-close {
  font-size: 14px;
  margin-top: 4px;
  border-radius: 4px;
  color: #999;
  background-color: #fff;
  border: 1px solid #999;
  line-height: 25px;
  width: 50px;
  text-align: center;
}

.tag-after-6 {
  background: #e1eaef;
  color: #333;
  padding: 0 5px;
  text-align: center;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  border-radius: 30px;

  &:before {
    border-radius: 30px;
  }
}
.badge-class-0 {
  color: #fff;
  border-color: #70bc46;
  background-color: #70bc46;
}

.badge-class-1 {
  color: #fff;
  border-color: #0097ff;
  background-color: #0097ff;
}
.badge-class-2 {
  color: #fff;
  border-color: #f07373;
  background-color: #f07373;
}
.badge-class-3 {
  color: #fff;
  border-color: #3cc791;
  background-color: #3cc791;
}
.badge-class-4 {
  color: #fff;
  border-color: #ff7239;
  background-color: #ff7239;
}
.badge-class-5 {
  color: #fff;
  border-color: #ffac2a;
  background-color: #ffac2a;
}

.top_tip {
  .ui-row {
    background-color: #fff;
  }
  .left_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fcb300;
    text-align: center;
    line-height: 50px;
  }
  .left_icon2 {
    background-color: #ff7360;
  }
  .left_icon3 {
    background-color: #39ccc5;
  }
  .text {
    .ui-text {
      font-size: 16px;
      color: #313338;
    }
    .ui-view {
      color: #9c9fa4;
      font-size: 12px;
    }
  }
}
</style>