<template>
 <div>

 <van-image  class="user-poster" :src="require('../../lib/img/benner.png')" />
<van-form @submit="onSubmit">
  <van-field
    v-model="username"
    name="用户名"
    label="用户名"
    placeholder="用户名/手机号"
    :rules="[{ required: true, message: '请填写用户名或手机号' }]"
  />
  <van-field
    v-model="password"
    type="password"
    name="密码"
    label="密码"
    placeholder="密码"
    :rules="[{ required: true, message: '请填写密码' }]"
  />
  <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">提交</van-button>
  </div>

<div  class="reg" style="margin: 16px;" @click="reg">
    注册账号
  </div>


</van-form>
 </div>
</template>

<script>
import Vue from "vue";
import { Toast, Form, Field, Button ,Image} from "vant";

import router from "../../router";
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Image);

export default {
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {

reg()
{

router.push("/register");

},



    onSubmit() {
      var param = { account: this.username, password: this.password };

      this.postRequest("/login", param).then(res => {
        window.console.log(res);
if (res.code == 200) {
          localStorage.setItem("NbiotToken", res.data);

        localStorage.setItem("ExpiredTime", parseInt(new Date().getTime()/1000)+43200);
router.push("/home");
        }
      });
    }
  },mounted() 
  {
     //Toast(localStorage.getItem("openid"));
  }
};
</script>