<template>
    <div id="app">

        <van-tabbar v-model="active" route  style=" border-top:1px solid #ccc">
            <!--<van-tabbar-item replace to="/cart" icon="chat-o" badge="5">消息</van-tabbar-item>-->



          <van-tabbar-item replace to="/home">
          <van-icon class="iconfont" class-prefix='icon' slot="icon"   name="home"></van-icon>
          <span>首页</span>
          </van-tabbar-item>


            <van-tabbar-item replace to="/device">
             <van-icon class="iconfont" class-prefix='icon' slot="icon"   name="device"></van-icon>
            <span>设备</span>
             </van-tabbar-item>


              <van-tabbar-item  replace to="/binddevice">
             <van-icon class="iconfont scanning" class-prefix='icon' slot="icon"   name="scanning"></van-icon>
            <span>绑定</span>
             </van-tabbar-item>


             <van-tabbar-item replace to="/message">
             <van-icon class="iconfont" class-prefix='icon' slot="icon"   name="message"></van-icon>
            <span>消息</span>
             </van-tabbar-item>

              <van-tabbar-item replace to="/about">
             <van-icon class="iconfont" class-prefix='icon' slot="icon"   name="user"></van-icon>
            <span>设置</span>
             </van-tabbar-item>



            <!--<van-tabbar-item replace to="/user" icon="setting-o">设置</van-tabbar-item>-->
        </van-tabbar>

        <router-view></router-view>
    </div>
</template>

<script>
import Vue from "vue";
import { Tabbar, TabbarItem, Icon } from "vant";

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);

export default {
  name: "App",
  data() {
    return {
      active: 0 //由于这里使用了vant 标签栏路由模式，该设置无效（但是此处不可删除）。可以自己尝试看看
    };
  },

  //初始化页面选中状态
  created() {

     window.localStorage.setItem('scanUrl',window.location.href.split('#')[0])
    //由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
    // console.log(this.$route);//打印当前路由属性
    if (this.$route.path === "/") {
      this.$router.push("/home");
    }

    
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 40px;*/
}

.iconfont{ font-size: 24px !important;}
.scanning { font-size: 28px !important;}
</style>