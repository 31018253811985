var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-image',{staticClass:"user-poster",attrs:{"src":require('../../lib/img/benner.png')}}),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"用户名","label":"用户名","placeholder":"用户名","rules":[{ required: true, message: '请填写用户名',trigger:'onSubmit' },
   


 { pattern: /^[a-zA-Z][a-zA-Z0-9_]{5,16}$/, message: '用户名5到16位（首字符为字母，可包括数字，下划线）'}

    
    
    ]},model:{value:(_vm.AdminName),callback:function ($$v) {_vm.AdminName=$$v},expression:"AdminName"}}),_c('van-field',{attrs:{"name":"Phone","label":"手机号码","placeholder":"手机号码","rules":[{ required: true, message: '请填写手机号码',trigger:'onSubmit' },
    
    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
    ]},model:{value:(_vm.Phone),callback:function ($$v) {_vm.Phone=$$v},expression:"Phone"}}),_c('van-field',{attrs:{"name":"验证码","label":"验证码","placeholder":"验证码","rules":[{ required: true, message: '请输入验证码' ,trigger:'onSubmit'}]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"size":"small","type":"primary","native-type":"button","disabled":_vm.isSmsSend},on:{"click":_vm.sendSmsCode}},[_vm._v(_vm._s(_vm.sendBtnText))])]},proxy:true}]),model:{value:(_vm.Captcha),callback:function ($$v) {_vm.Captcha=$$v},expression:"Captcha"}}),_c('van-field',{attrs:{"type":"password","name":"密码","label":"密码","placeholder":"密码","rules":[{ required: true, message: '请填写密码',trigger:'onSubmit' }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"type":"password","name":"密码","label":"确认密码","placeholder":"确认密码","rules":[{ required: true, message: '请确认密码',trigger:'onSubmit' },

 
    { validator: _vm.validator, message: '两次输入密码不一致' }
    
    
    ]},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"block":"","type":"primary","native-type":"submit"}},[_vm._v("注册")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }