import Vue from 'vue';
import App from './App';
//import { router } from './router';

import router from './router';//这里是自定义路由规则，文章末尾贴出【自定义路由规则】

import './lib/font/iconfont.css';
import {POST} from './lib/utils/axios.js';
import {GET} from './lib/utils/axios.js';

import {nativePOST} from './lib/utils/axios.js';
import {nativeGET} from './lib/utils/axios.js';

import {sha} from "./lib/utils/sha1.js";


Vue.prototype.postRequest = POST;//this.postRequest(url,param).then(res => {})
Vue.prototype.getRequest = GET;//this.getRequest(url,param).then(res => {})

Vue.prototype.sha1 = sha;

Vue.prototype.nativePostRequest = nativePOST;//this.postRequest(url,param).then(res => {})
Vue.prototype.nativeGetRequest = nativeGET;//this.getRequest(url,param).then(res => {})


new Vue({
  router,
  el: '#app',
  render: h => h(App)
});


/*

import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';

import router from './router';//这里是自定义路由规则，文章末尾贴出【自定义路由规则】

Vue.config.productionTip = false;
Vue.use(Vant);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');*/